import axios from "axios";

export async function searchAction (params) {
    let config = {
        headers: {'content-type': 'Application/json'},
        params
    }
    const response = await axios.get('catalogue-search-json', config)
    if (response.statusText === "OK" && response.data) {
        if (response.data.elasticResponse) {
            response.data = {
                ...response.data.elasticResponse,
                ...response.data
            }
        }
        return response
    } else {
        throw {response : response}
    }
}

// export async function searchAnnonceAction (params) {
//     let config = {
//         headers: {'content-type': 'Application/json'},
//         params
//     }
//     const response = await axios.get('catalogue-search-annonce-json/'+params.id, config)
//     if (response.statusText === "OK" && response.data) {
//         if (response.data.elasticResponse) {
//             response.data = {
//                 ...response.data.elasticResponse,
//                 ...response.data
//             }
//         }
//         return response
//     } else {
//         throw {response : response}
//     }
// }

export async function autocomplete (params, url = '/autocomplete-json') {
    let config = {
        headers: {'content-type': 'Application/json'},
        params
    }
    const response = await axios.get(url, config)
    if (response.statusText === "OK" && response.data) {
        return response
    } else {
        throw {response : response}
    }
}
